/* eslint-disable */

import momentTime from "moment-timezone";
import { LOGIN_ROOT } from "../service/apiUrls";
import { authTokenCookie } from "../utils/CookieMaker";
import store from "../redux/store/index";
import {
  setAPIMKey,
  setSelectedProductItem,
  setUserSFKeyMap,
} from "component/screens/giftCards/giftCardAction";
export const logout = () => {
  sessionStorage.clear();
  let { userInfo } = authTokenCookie();
  sessionStorage.setItem("sessionId", userInfo.userId);
  clearRedux("logout");
  document.cookie.split(";").map((cookies) => {
    document.cookie = cookies
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    return cookies;
  });
  let url = LOGIN_ROOT.substring(0,LOGIN_ROOT.length)
  window.location.replace(`${url}/azurelogout`);
};

export const serverlogout = () => {
  sessionStorage.clear();
  let { userInfo } = authTokenCookie();
  sessionStorage.setItem("sessionId", userInfo.userId);
  clearRedux("logout");
  //localStorage.clear();
  let url = LOGIN_ROOT.substring(0,LOGIN_ROOT.length)

  window.location.replace(`${url}/loginError`);
};
export const fileNameBuilder = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") {
      delete obj[key];
    }
  });
  let query = "";
  for (let d in obj) {
    query += obj[d] + "_";
  }
  return query.slice(0, -1);
};
export const queryBuilder = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") {
      delete obj[key];
    }
  });
  let query = "?";
  for (let d in obj) query += d + "=" + obj[d] + "&";
  return query.slice(0, -1);
};

export const clearRedux = (checkLogout) => {
  let state = store.getState();
  if (checkLogout !== "logout") {
    const key = state.closeTheLoop.key;
    const mapKey = state.closeTheLoop.userSFMap;
    store.dispatch({ type: "CLEAR_STORE", value: null });
    store.dispatch(setAPIMKey(key));
    store.dispatch(setUserSFKeyMap(mapKey));
  } else {
    store.dispatch({ type: "CLEAR_STORE", value: null });
    localStorage.clear();
  }
};

export const clearState = (storeToClear) => {
  store.dispatch({ type: storeToClear, value: null });
};

export const getUserID = () => {
  let { userInfo } = authTokenCookie();
  return userInfo.userId;
};

export const convertToMST = (date) => {
  return momentTime(new Date(date)).tz("America/Denver").format("YYYY-MM-DD");
};
export const formSelectData = (placeholder, data) => {
  let tempData = [{ id: placeholder, value: placeholder }];
  data.forEach((item) => {
    let tmpObj = {
      value: item.value,
      id: item.id,
    };
    tempData = [...tempData, tmpObj];
  });
  return tempData;
};

export const formAutoCompleteData = (placeholder, data) => {
  let tempData = [];
  if (placeholder !== "") {
    tempData = [{ title: placeholder, value: placeholder }];
  }

  data.forEach((item) => {
    let tmpObj = {
      title: item.value,
      value: item.id,
    };
    tempData = [...tempData, tmpObj];
  });

  return tempData;
};

export const phoneNumberFormatter = (phone) => {
  if (phone) {
    let phnNum = phone.toString();
    return `(${phnNum.slice(0, 3)})-${phnNum.slice(3, 6)}-${phnNum.slice(6)}`;
  }
};

export const getToken = (role) => {
  let tokenObj = {
    "Division User": "",
    "District User": "",
    "Store User": "",
    "Backstage Non CSC User": "",
  };
  let keys = Object.keys(tokenObj);
  let token = "";
  keys.forEach((item) => {
    if (role === item) token = tokenObj[item];
  });
  return token;
};

export const formatDetails = (userArray, status) => {
  let details = {
    districtId: "",
    districtName: "",
    district: [],
    store: [],
    divisionId: "",
    divisionName: "",
    storeId: "",
    storeName: "",
    status: status,
  };
  userArray.forEach((item) => {
    if (item.divisionId) {
      details.divisionId = item.divisionId;
      details.divisionName = item.divisionName;
    }
    if (item.districtId) {
      // let disObj = {value: item.districtId, title: item.districtNbr}
      details.districtId = item.districtId;
      details.districtName = item.districtNbr;
      // details.district.push(disObj)
    }
    if (item.storeId) {
      // let storeObj = { value: item.storeId, title: item.storeName }
      details.storeId = item.storeId;
      details.storeName = item.storeName;
      // details.store.push(storeObj)
    }
  });
  return details;
};

export const hasWhiteSpace = (val) => {
  return /^\s|\s$/.test(val);
};
export const appendZeros = (val, maxLength, minLength) => {
  let appendedVal = val;
  let valLen = val ? val.length : 0;
  if (val && valLen >= minLength) {
    let appendZero = ``;
    let zerosToBeAppended = maxLength - valLen;
    if (zerosToBeAppended > 0) {
      for (let i = 0; i < zerosToBeAppended; i++) {
        appendZero = `${appendZero}0`;
      }
      appendedVal = `${appendZero}${appendedVal}`;
    }
  }
  return appendedVal;
};

export const getProductId = (products, lastItems) => {
  let productObj = [];

  products.map((item,idx) => {
    let arraynew = [];
    let itemObj = {}
    itemObj.isSerialTyped = 0;
    itemObj.serialNumber = "";
    itemObj.userEnteredQuantity = 0;
    itemObj.isSplitClicked = 0;
    itemObj.serialMapped = 0;
    itemObj.productId = item.productId;
    itemObj.orderProductId = item.orderProductId;
    itemObj.productType = item.productType;
    itemObj.productQty = item.productQty;
   
    if(lastItems !== undefined && lastItems.length > 0 && item.orderProductId === lastItems[idx].orderProductId){
      itemObj.items = lastItems[idx].items;
      itemObj.avlProductQty = lastItems[idx].avlProductQty;
    }else{
      itemObj.items = arraynew;
      itemObj.avlProductQty = item.productQty;
    }
    productObj.push(itemObj);
    
  });

  return productObj;
};

export const updateProductItems = (productId, val, productDatas, type) => {
  let pData = productDatas;
  if (type === "serialNumberDelete") {
    let itemArr;
    let filterArr;
    pData.map((data) => {
      if (productId === data.orderProductId) {
        itemArr = data.items;
        filterArr = itemArr.filter(
          (filterData) => filterData.userSerial !== val.userSerial
        );
        data.items = filterArr;
        data.serialMapped =
          parseInt(val.productQty) - parseInt(val.serialTotalQty);
        data.serialNumber = "";
        data.isSerialTyped = 0;
        data.isSplitClicked = 0;
        if (data.avlProductQty === 0) {
          data.avlProductQty = val.serialTotalQty;
        } else {
          data.avlProductQty =
            parseInt(data.avlProductQty) + parseInt(val.serialTotalQty);
        }

        data.userEnteredQuantity = 0;
      }
      return data;
    });
  }

  if (type === "reset") {
    pData.map((data) => {
      data.serialNumber = "";
      data.isSerialTyped = 0;
      data.userEnteredQuantity = 0;
      data.isSplitClicked = 0;
      data.serialMapped = 0;
      data.items = [];
      return data;
    });
  }

  if (type === "update") {
    let totalQty =
      parseInt(productId.lastCardInSeries) -
      parseInt(productId.firstCardInSeries) +
      1;

    pData.map((data) => {
      if (productId.orderProductId === data.orderProductId) {
        data.isSerialTyped = 0;
        data.isSplitClicked = 0;
        data.userEnteredQuantity = 0;
        data.serialNumber = "";
        data.serialMapped = parseInt(data.serialMapped) + parseInt(totalQty);
        data.avlProductQty = parseInt(data.avlProductQty) - parseInt(totalQty);
        data.items.push({
          totalQty: totalQty,
          serialRange:
            productId.firstCardInSeries + "-" + productId.lastCardInSeries,
          userSerial: productId.firstCardInSeries,
        });
      }
      return data;
    });
  }

  if (type === "splitCheckboxUpdate") {
    pData.map((data) => {
      if (data.orderProductId === productId) {
        data.isSplitClicked = val;
      }
      return data;
    });
  }
  if (type === "userQuantutyAndOtherUpdate") {
    pData.map((data) => {
      if (data.orderProductId === productId) {
        data.userEnteredQuantity = 0;
        data.serialNumber = "";
        data.isSplitClicked = 0;
        data.avlProductQty = val;
      }
      return data;
    });
  }
  if (type === "userQuantutyUpdate") {
    pData.map((data) => {
      if (data.orderProductId === productId) {
        data.userEnteredQuantity = val;
      }
      return data;
    });
  }
  if (type === "serialNumberUpdate") {
    pData.map((data) => {
      if (data.orderProductId === productId) {
        data.serialNumber = val;
        data.serialMapped = 0;
        data.isSerialTyped = val !== "" && val.length === 11 ? 1 : 0;
      }
      return data;
    });
  }
  if (type === "serialNumberAndListClear") {
    pData.map((data) => {
      if (data.orderProductId === productId) {
        data.serialNumber = "";
        data.isSplitClicked = 0;
        data.userEnteredQuantity = 0;
        data.serialMapped = 0;
        data.items = data.items;
        data.avlProductQty = data.avlProductQty;
        data.isSerialTyped = val !== "" && val.length === 11 ? 1 : 0;
      }
      return data;
    });
  }

  store.dispatch(setSelectedProductItem(pData));
};

export const getOrderStatusList = (options, title) => {
  let optionList = [];
  options
    ?.filter((option) => option.title !== title)
    .map((status) => {
      optionList.push(status.title);
    });

  return optionList;
};

export const getDivisionName = (divisions, divisionId) => {
  let optionList = [];
  divisions
    ?.filter((option) => option.divisionId === divisionId)
    .map((division) => {
      optionList.push(division.value);
    });

  return optionList[0];
};
